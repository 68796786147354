import { updateItemQuantityInDB } from "@/components/features/cart-modal/actions";
import { Button } from "@/components/shared/ui/button";
import { Input } from "@/components/shared/ui/input";
import { Label } from "@/components/shared/ui/label";
import { useCartStore } from "@/state-management/stores/cart-store";
import { Cannabis, Minus, Plus, Trash } from "lucide-react";
import React, { useState, useTransition } from "react";
import { useFormState } from "react-dom";

function SubmitButton({
    type,
    onClick,
    disabled = false,
    lastUnit = false,
    isActive = false
}: {
    type: string;
    // eslint-disable-next-line no-unused-vars
    onClick: (e: React.MouseEvent) => void;
    disabled?: boolean;
    lastUnit?: boolean;
    isActive?: boolean;
}) {
    const ariaLabel = {
        "plus-case": "Increase case quantity",
        "minus-case": "Decrease case quantity",
        "plus-unit": "Increase unit quantity",
        "minus-unit": "Decrease unit quantity"
    }[type];

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
    };

    return (
        <Button
            variant="outline"
            size="icon"
            onClick={handleClick}
            className={`h-10 w-10 rounded-sm md:h-8 md:w-8 ${isActive ? "bg-cart p-0 text-white" : ""}`}
            aria-label={ariaLabel}
            disabled={disabled}
        >
            {type.includes("plus") ? (
                <Plus className="h-3 w-3" />
            ) : lastUnit ? (
                <Trash className="h-3 w-3" />
            ) : (
                <Minus className="h-3 w-3" />
            )}
        </Button>
    );
}
function UnitCaseReducer(input: { units: number; cases: number; wholesaleUnitsPerCase: number }) {
    const totalUnits = input.cases * input.wholesaleUnitsPerCase + input.units;
    return {
        cases: Math.floor(totalUnits / input.wholesaleUnitsPerCase),
        units: totalUnits % input.wholesaleUnitsPerCase
    };
}

const QuantityInput = ({
    label,
    value,
    onBlur,
    onChange,
    onPlusClick,
    onMinusClick,
    disabled,
    isPending,
    isLastUnit,
    isActive = false
}: {
    label: string;
    value: number;
    // eslint-disable-next-line no-unused-vars
    onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
    // eslint-disable-next-line no-unused-vars
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onPlusClick: () => void;
    onMinusClick: () => void;
    disabled: boolean;
    isPending: boolean;
    isLastUnit: boolean;
    isActive?: boolean;
}) => (
    <div className={`flex w-fit items-center justify-between gap-2`}>
        <Label className={`w-10 text-right text-sm font-medium text-gray-600 underline`}>
            {label}
        </Label>
        <div className="flex w-fit grid-cols-3 items-center gap-1 ">
            <SubmitButton
                type={`minus-${label.toLowerCase()}`}
                onClick={onMinusClick}
                disabled={disabled || isPending}
                lastUnit={isLastUnit}
                isActive={isActive}
            />
            {isPending && (value == 420 || value == 69) ? (
                <div className="flex h-10 w-12 items-center justify-center rounded border md:h-8 md:w-10">
                    <Cannabis size={18} color="#98b399" fill="#98b399" />
                </div>
            ) : (
                <Input
                    type="number"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={(e) => e.target.select()}
                    className={`h-10 w-12 px-1 text-center text-sm [appearance:textfield] md:h-8 md:w-10 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none `}
                    onClick={(e) => e.stopPropagation()}
                    disabled={isPending}
                />
            )}
            <SubmitButton
                type={`plus-${label.toLowerCase()}`}
                onClick={onPlusClick}
                disabled={isPending}
                isActive={isActive}
            />
        </div>
    </div>
);

export default function CaseUnitInput({
    productID,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    promotionID,
    initialQuantity,
    minOrderUnits
}: {
    productID: number;
    wholesaleUnitsPerCase: number;
    sellByCaseOnly: boolean;
    promotionID: number | null;
    initialQuantity: {
        cases: number;
        units: number;
    };
    minOrderUnits: number;
}) {
    const { updateCartItem } = useCartStore();
    // eslint-disable-next-line no-unused-vars
    const [message, formAction] = useFormState(updateItemQuantityInDB, null);
    const [isPending, startTransition] = useTransition();
    const [localQuantity, setLocalQuantity] = useState(initialQuantity);

    const initialUnits = initialQuantity.cases * wholesaleUnitsPerCase + initialQuantity.units;

    const handleOnBlur = () => async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const units = localQuantity.cases * wholesaleUnitsPerCase + localQuantity.units;

        saveNewQuantity(units);
    };

    const saveNewQuantity = async (units: number) => {
        if (!isNaN(units) && units >= 0 && units !== initialUnits) {
            const payload = {
                productID: productID,
                quantity: Math.max(0, units),
                promotionID: promotionID
            };

            startTransition(() => {
                updateCartItem(
                    payload.productID,
                    "set-units",
                    payload.promotionID,
                    payload.quantity
                );
            });

            formAction(payload);

            setLocalQuantity(
                UnitCaseReducer({
                    cases: 0,
                    units: units,
                    wholesaleUnitsPerCase: wholesaleUnitsPerCase
                })
            );
        }
    };

    const handleButtonClick = async (
        type: "plus-case" | "minus-case" | "plus-unit" | "minus-unit"
    ) => {
        const totalUnits = localQuantity.cases * wholesaleUnitsPerCase + localQuantity.units;
        let newQuantity = totalUnits;

        let newCases = Number(localQuantity.cases);
        let newUnits = Number(localQuantity.units);

        let step = 1;

        if (
            minOrderUnits > 1 &&
            ((totalUnits === 0 && type === "plus-unit") ||
                (totalUnits === minOrderUnits && type === "minus-unit"))
        ) {
            step = minOrderUnits;
        }

        switch (type) {
            case "plus-case":
                newQuantity += wholesaleUnitsPerCase;
                newCases += 1;
                break;
            case "minus-case":
                if (newCases > 0) {
                    newQuantity -= wholesaleUnitsPerCase;
                    newCases -= 1;
                }
                break;
            case "plus-unit":
                newQuantity += step;
                newUnits += step;
                break;
            case "minus-unit":
                if (newUnits > 0) {
                    newQuantity -= step;
                    newUnits -= step;
                } else if (newCases > 0) {
                    newCases -= step;
                    newUnits = wholesaleUnitsPerCase - 1;
                    newQuantity -= step;
                }
                break;
        }

        if (newQuantity !== 0 && newQuantity < minOrderUnits) {
            newQuantity = minOrderUnits;
        }

        saveNewQuantity(newQuantity);
    };

    const setLocalQuantityOnChange =
        (type: "cases" | "units") => (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = Number(e.target.value);
            let totalUnits = localQuantity.cases * wholesaleUnitsPerCase + localQuantity.units;

            if (type === "cases") {
                totalUnits = value * wholesaleUnitsPerCase + localQuantity.units;
            } else {
                totalUnits = localQuantity.cases * wholesaleUnitsPerCase + value;
            }

            if (totalUnits !== 0 && totalUnits < minOrderUnits) {
                totalUnits = minOrderUnits;
            }

            setLocalQuantity(
                UnitCaseReducer({
                    cases: 0,
                    units: totalUnits,
                    wholesaleUnitsPerCase: wholesaleUnitsPerCase
                })
            );
        };

    const totalUnits = localQuantity.cases * wholesaleUnitsPerCase + localQuantity.units;

    return (
        <div className={`flex w-fit justify-end `}>
            <div className="flex w-fit flex-col justify-items-end gap-x-2 gap-y-0.5 ">
                {minOrderUnits > 1 ? (
                    <div
                        className={`text-sm ${totalUnits >= minOrderUnits ? "text-gray-400" : ""}`}
                    >
                        Minimum order: {minOrderUnits} units
                    </div>
                ) : null}
                <div className="flex w-fit flex-col justify-items-end gap-x-2 gap-y-0.5 ">
                    <QuantityInput
                        label="Cases"
                        value={localQuantity.cases}
                        onBlur={handleOnBlur()}
                        onChange={setLocalQuantityOnChange("cases")}
                        onPlusClick={() => handleButtonClick("plus-case")}
                        onMinusClick={() => handleButtonClick("minus-case")}
                        disabled={localQuantity.cases === 0}
                        isPending={isPending}
                        isLastUnit={totalUnits === wholesaleUnitsPerCase}
                        isActive={totalUnits > 0}
                    />

                    {!sellByCaseOnly && (
                        <QuantityInput
                            label="Units"
                            value={localQuantity.units}
                            onBlur={handleOnBlur()}
                            onChange={setLocalQuantityOnChange("units")}
                            onPlusClick={() => handleButtonClick("plus-unit")}
                            onMinusClick={() => handleButtonClick("minus-unit")}
                            disabled={totalUnits === 0}
                            isPending={isPending}
                            isLastUnit={totalUnits === minOrderUnits}
                            isActive={totalUnits > 0}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
